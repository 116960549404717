


























































import { Component, Vue, Watch } from 'vue-property-decorator'
import { UploadStep, UploadStore, UploadType } from '~/store/upload'
import DropZone from '#/components/DropZone.vue'
import UploadBoxBeatNew from '#/components/UploadBoxBeatNew.vue'
import UploadBoxBeatMatch from '#/components/UploadBoxBeatMatch.vue'
import { fetchUploadedBeatsData } from '#/api/beat'
import AlertBox from '#/components/AlertBox.vue'

@Component({ components: { AlertBox, DropZone, UploadBoxBeatNew, UploadBoxBeatMatch } })
export default class BeatsUploadPage extends Vue {
    UploadType = UploadType
    UploadStep = UploadStep

    @UploadStore.State('stagedFiles') stagedFiles: boolean
    @UploadStore.State('step') step: UploadStep
    @UploadStore.State('type') type: UploadType

    uploadedBeatsData: IUploadedBeatsData = {
        can_upload_beats: true,
        reaching_beats_upload_limit: false
    }

    get queryType() {
        return this.$route.query.type
    }

    @UploadStore.Mutation('ALLOW_SERVER_UPLOAD') ALLOW_SERVER_UPLOAD: () => void
    @UploadStore.Mutation('RESET_STAGED_FILES') RESET_STAGED_FILES: () => void

    @Watch('queryType')
    onQueryTypeChange(newValue: string) {
        if (newValue) this.RESET_STAGED_FILES()
    }

    async loadData() {
        this.uploadedBeatsData = await fetchUploadedBeatsData()
    }

    created() {
        this.RESET_STAGED_FILES()
        this.loadData()
    }
}
