








import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import { UploadStore } from '~/store/upload'

@Component
export default class UploadProgressBar extends Vue {
    @Prop() itemToUpload: IItemToUpload

    @UploadStore.Mutation('DELETE_STAGED_FILE') DELETE_STAGED_FILE: (id: IItemToUpload['id']) => void

    cancelUpload() {
        this.itemToUpload.cancelTokenSource.cancel('Operation canceled by the user.')
        this.DELETE_STAGED_FILE(this.itemToUpload.id)
    }
}
