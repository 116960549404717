








import { Vue, Prop, Component, Watch } from 'vue-property-decorator'

import { UploadType, UploadStore } from '~/store/upload'

@Component
export default class DropZone extends Vue {
    @Prop() zoneType: UploadType

    UploadType = UploadType

    get acceptFile() {
        if (this.zoneType === UploadType.Public) return 'image/*'
        if (this.zoneType === UploadType.TaggedBeat) return '.mp3'
        else if (this.zoneType === UploadType.MatchBeat) {
            return 'audio/*, application/*, .zip, .rar, application/x-rar-compressed, application/octet-stream, application/zip, application/octet-stream'
        }
        return 'audio/*'
    }

    get layoutSettings() {
        const settings = { icon: '', heading: '', paragraph: '' }
        if (this.zoneType === UploadType.NewBeat) {
            settings.icon = 'box'
            settings.heading = 'Upload new beats'
            settings.paragraph = 'Upload WAV or MP3 audio to create new beats. Tagged versions are automatically generated.'
        } else if (this.zoneType === UploadType.MatchBeat) {
            settings.icon = 'folders'
            settings.heading = 'Upload more files'
            settings.paragraph = 'Upload MP3, WAV or Trackouts to add to or modify existing beats.'
        } else if (this.zoneType === UploadType.Public) {
            settings.icon = 'camera'
            settings.heading = 'Upload artworks'
            settings.paragraph = 'Upload images to add to existing beats.'
        } else if (this.zoneType === UploadType.TaggedBeat) {
            settings.icon = 'folders'
            settings.heading = 'Upload tagged beats'
            settings.paragraph = 'Upload mp3 tagged files of your beats to prevent artists from stealing the instrumentals.'
        }
        return settings
    }

    multipleFilesUpload(fileList: File[]) {
        const dispatchParams = { fileList, uploadType: this.zoneType }
        this.$store.dispatch('upload/multipleFilesUpload', dispatchParams)
    }
}
