















































import { Vue, Prop, Component, Watch, Mixins } from 'vue-property-decorator'

import UploadProgressBar from '#/components/UploadProgressBar.vue'
import BeatInfoDropdown from '#/components/BeatInfoDropdown.vue'
import { UploadStore, UploadType } from '~/store/upload'
import { createBeat } from '#/api/beat'
import { ModalStore, IModalParams, ModalType } from '~/store/modal'
import { UserStore } from '../store/user'
import { FileExtension } from '#/mixins/file-extension'
import { grabAudioMetaData } from '~/services/audio-metadata'

@Component({ components: { UploadProgressBar, BeatInfoDropdown } })
export default class UploadBoxBeatNew extends Mixins(FileExtension) {
    @Prop() itemToUpload: IItemToUpload

    beat: Partial<IBeatProducer> = {
        // name: this.itemToUpload.file.name,
        name: '',
        artist_type: null,
        genre: null,
        main_instrument: null,
        bpm: 0,
        image_url: '',
        trackout_external_url: '',
        sampled: false,
        length: 0
    }
    errorOnArtistType = false
    errorOnGenre = false
    errorOnMainInstrument = false
    regexUrl = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/

    @UploadStore.State('serverUploadIsAllowed') serverUploadIsAllowed: boolean
    @UserStore.State('authenticatedUser') authenticatedUser: IUserProducer

    @UploadStore.Mutation('DELETE_STAGED_FILE') DELETE_STAGED_FILE: () => void
    @UploadStore.Mutation('RESET_STAGED_FILES') RESET_STAGED_FILES: () => void
    @ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: (modalParams: IModalParams) => void
    @UploadStore.Action('singleFileUpload') singleFileUpload: (IClientUploadParams: IClientUploadParams) => Promise<IUploadReturn>

    setArtistType(artistType: IArtistType) {
        this.beat.artist_type = artistType
        this.errorOnArtistType = false
    }

    setGenre(genre: IGenre) {
        this.beat.genre = genre
        this.errorOnGenre = false
    }

    setMainInstrument(instrument: IInstrument) {
        this.beat.main_instrument = instrument
        this.errorOnMainInstrument = false
    }

    async addImageToBeat(fileList: FileList) {
        try {
            const { url } = await this.singleFileUpload({ fileList, uploadType: UploadType.Public, maxPxSize: 500 })
            this.beat.image_url = url
        } catch (error) {
            this.SET_ACTIVE_MODAL({ modal: ModalType.Error })
        }
    }

    async grabBeatMetaData() {
        const { bpm, length } = await grabAudioMetaData(this.itemToUpload.file)
        this.beat.length = length
        this.beat.bpm = bpm
    }

    @Watch('serverUploadIsAllowed')
    async onServerUploadIsAllowedChange(newValue: any, oldValue: any) {
        if (!this.beat.artist_type) this.errorOnArtistType = true
        if (!this.beat.genre) this.errorOnGenre = true
        if (!this.beat.main_instrument) this.errorOnMainInstrument = true
        if (!this.beat.artist_type || !this.beat.genre || !this.beat.main_instrument) return

        const demoDownloadAllowed = this.authenticatedUser ? this.authenticatedUser.demo_download_allowed : true
        // set demo download based on default settings
        const beat: any = { ...this.beat, demo_download_allowed: demoDownloadAllowed }
        // we set the file name in the appropriate field depending on the file extension
        if (this.fileExtension === 'MP3') beat.mp3_file_name = this.itemToUpload.url
        else beat.wav_file_name = this.itemToUpload.url

        try {
            await createBeat(beat)
            this.SET_ACTIVE_MODAL({ modal: ModalType.Success })
            Vue.router.push({ name: 'BeatsCatalog', params: { pageIndex: 1 } })
        } catch (error) {
            this.SET_ACTIVE_MODAL({ modal: ModalType.Error })
        }
    }

    created() {
        this.grabBeatMetaData()
    }
}
