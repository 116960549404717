// @ts-ignore
import detect from 'bpm-detective'

export async function grabAudioMetaData(audioFile: File): Promise<{ bpm: number; length: number }> {
    const metadata = { bpm: 0, length: 0 }
    // @ts-ignore
    const AudioContext = window.AudioContext || window.webkitAudioContext
    try {
        // decoding
        const response = await fetch(URL.createObjectURL(audioFile))
        let buffer: any = await response.arrayBuffer()
        buffer = await new Promise((resolve, reject) => {
            new AudioContext().decodeAudioData(buffer, resolve, reject)
        })
        // once length is available, set length
        if (buffer.duration) metadata.length = Math.round(buffer.duration)
        // set bpms
        metadata.bpm = detect(buffer)
    } catch (err) {
        console.log(`Error during audio metadata encoding 'grabAudioMetaData()'`)
        console.error(err)
    }
    return metadata
}
