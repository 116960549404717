






























import Vue from 'vue'
import { Prop, Component, Watch, Mixins } from 'vue-property-decorator'
import UploadProgressBar from '#/components/UploadProgressBar.vue'
import { UploadStore, UploadType } from '~/store/upload'
import { BeatStore } from '#/store/beat'
import { fetchAllBeatsLight, updateBeat } from '#/api/beat'
import { Dropdowns } from '../../shared/mixins/dropdowns'
import { FileExtension } from '#/mixins/file-extension'

@Component({ components: { UploadProgressBar } })
export default class UploadBoxBeatMatch extends Mixins(Dropdowns, FileExtension) {
    @Prop() itemToUpload: IItemToUpload
    @Prop() isImage: boolean

    image = ''
    beatToPatch: Partial<IBeatProducer> = {
        id: null,
        name: ''
    }
    searchInput = ''
    entireCatalog: IBeatLight[] = []

    @UploadStore.State('serverUploadIsAllowed') serverUploadIsAllowed: boolean
    @UploadStore.State('type') type: UploadType

    get filteredBeats(): IBeatLight[] {
        return this.entireCatalog.filter(element => element.name.match(new RegExp(this.searchInput, 'i')))
    }

    @UploadStore.Mutation('DELETE_STAGED_FILE') DELETE_STAGED_FILE: () => void

    setBeat(beat: IBeatProducer) {
        // if the upload was an image, we simply match the ids
        this.closeMenuDropdown()
        this.searchInput = ''

        this.beatToPatch = { id: beat.id, name: beat.name }

        // if it was a tagged beat with update the tagged_file_name property
        if (this.type === UploadType.TaggedBeat) this.beatToPatch.tagged_file_name_thecharts = this.itemToUpload.url
        // if it's an image, set the image_url property
        else if (this.type === UploadType.Public) this.beatToPatch.image_url = this.itemToUpload.url
        // it it's an audio file
        else {
            // otherwise we update mp3, wav or trackout properties based on fileExtension
            switch (this.fileExtension) {
                case 'MP3':
                    this.beatToPatch.mp3_file_name = this.itemToUpload.url
                    break
                case 'WAV':
                    this.beatToPatch.wav_file_name = this.itemToUpload.url
                    break
                case 'ZIP':
                case 'RAR':
                    this.beatToPatch.trackout_file_name = this.itemToUpload.url
                    break
            }
        }
    }

    renderImage() {
        const reader = new FileReader()
        reader.onload = (e: any) => (this.image = e.target.result)
        reader.readAsDataURL(this.itemToUpload.file)
    }

    async fetchAllBeatsLight() {
        this.entireCatalog = await fetchAllBeatsLight()
    }

    @Watch('serverUploadIsAllowed')
    async onServerUploadIsAllowedChange() {
        await updateBeat(this.beatToPatch)
        Vue.router.push({ name: 'BeatsCatalog', params: { pageIndex: 1 } })
    }

    mounted() {
        if (this.isImage) this.renderImage()
        this.fetchAllBeatsLight()
    }
}
